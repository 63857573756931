import Vue from "vue";
import store from "./store.js";
import FormWizard from "./vue/FormWizard.vue";
import VueScrollTo from "vue-scrollto";
import Crypto from "./helpers/Crypto";


export default class SiOnSAimait
{
    constructor ()
    {
        this.setupVue();
    }

    setupVue ()
    {
        Vue.config.devtools = true;
        const components = {FormWizard};

        Vue.use(VueScrollTo);


        let vue = new Vue(
            {
                store,
                components,
                
                computed:
                    {
                    
                    },
                
                
                methods:
                    {

                    },
                    
                    created()
                    {
                        
                    },

                    mounted()
                    {

                    }
            }
        ).$mount ("#container");
    }
}