import { render, staticRenderFns } from "./GroupSection.vue?vue&type=template&id=3f85f3f7&scoped=true&"
import script from "./GroupSection.vue?vue&type=script&lang=js&"
export * from "./GroupSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f85f3f7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/si-on-s-aimait/webpack/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f85f3f7')) {
      api.createRecord('3f85f3f7', component.options)
    } else {
      api.reload('3f85f3f7', component.options)
    }
    module.hot.accept("./GroupSection.vue?vue&type=template&id=3f85f3f7&scoped=true&", function () {
      api.rerender('3f85f3f7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/vue/forms/GroupSection.vue"
export default component.exports