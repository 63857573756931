<template>
    <div class="row">
        <div class="col">
            <section class="card">
                <header class="card-header">
                    <h2 class="card-title">Confirmation</h2>
                </header>
                <div class="card-body">
                    <form class="form-horizontal form-bordered" action="#">

                        <div class="form-group row">

                            <div class="col">
                                <div class="input-group">
                                    <span v-html="messageSuccess"></span>
                                </div>

                            </div>
                        </div>

                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputSuccess",

        computed:
            {
                messageSuccess ()
                {
                    return this.$store.state.success_msg;
                }
            }
    }
</script>

<style scoped>

</style>