import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import lodash       from 'lodash';
import Crypto from "./helpers/Crypto.js";


const state =
	{
		title: "",
		success_msg: "",
		fields: [],
		currentGroup: 0,
		userData: {},
		form_id : "",
		submit_id: 0,
		last_progress : 0,
		progressTimeoutID : -1,
		isReady:false,
		currentData: {},


		forms: {}



	};

const getters =
	{

	};

const actions =
	{
		get_form: async (context, payload) =>
		{


			let json = await axios.get("/api/form/" + payload);//dynamic
			//let json = await axios.get(`https://duoproductions.s3.amazonaws.com/si-on-s-aimait/${payload}.json`); //static

			context.commit("form", json.data);
			context.commit("formId", payload);
		},

		get_forms: async (context, payload) =>
		{

			let arrForms = payload.split(",")
			let objJson = {};

			for (let i = 0; i < arrForms.length; i++)
			{
				let id = arrForms[i]
				let form = await axios.get("/api/form/" + id);//dynamic

				objJson[id] = form.data;
			}
			//let json = await axios.get(`https://duoproductions.s3.amazonaws.com/si-on-s-aimait/${payload}.json`); //static

			context.commit("forms", objJson);
		},

		init : async ({commit}) =>
		{
			let currentData = await axios.get("/api/current");//dynamic

			commit("currentData", currentData.data);
		},


		userData: (context, payload) =>
		{
			context.commit("userData", payload);
			context.dispatch("progressDebounce");

			//_.debounce( () => context.dispatch( "progress", payload) , 1000);



		},

		progressDebounce (context)
		{

			let last = context.state.last_progress;
			let current = (new Date()).getTime();


			let elapsed = current - last;
			context.state.last_progress = current;

			if (elapsed < 10000)
			{
				clearInterval(context.state.progressTimeoutID);
			}


			context.state.progressTimeoutID = setTimeout( () => context.dispatch("progress"), 10000);


		},

		remove_progress: async (context) =>
		{
			let strURL          = 'api/form/progress/' + context.state.form_id;

			if (context.state.submit_id != 0)
				strURL += "/" + context.state.submit_id;

			let config  = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				}
			};

			let deleted = await axios.delete(

				BASE_URL + strURL,
				config

			);


		},


		progress: async (context) =>
		{

			let strURL          = 'api/form/progress/' + context.state.form_id;

			if (context.state.submit_id != 0)
				strURL += "/" + context.state.submit_id;

			let config  = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				}
			};

			let submit = await axios.post(

				BASE_URL + strURL,
				context.state.userData,
				config

			);

			let data = submit.data;
			let id = data.submit_id;

			context.commit("submitId", id);
		},

		submit: async (context, payload) =>
		{
			let strURL          = 'api/form/' + payload.form_id;
			let config  = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				}
			};

			let submit = await axios.post(

				BASE_URL + strURL,
				payload.userData,
				config

			);


			context.dispatch("remove_progress");
		},


		selectForm (context, payload)
		{

		}

	};

const mutations =
	{
		form: (state, payload) =>
		{
			state.title = payload.title;
			state.success_msg = payload.success_msg;

			Vue.set(state, "fields", payload.fields);
			Vue.set(state, "isReady", true);

			for (let i = 0; i < payload.fields.length; i++)
			{
				let field = payload.fields[i];
				Vue.set(state.userData, field.id.toString(), "");

			}



		},




		forms (state, payload)
		{
			Vue.set(state, "forms", payload);

			state.title = "Soumettre une candidature";

			Vue.set(state, "isReady", true);
		},


		currentData (state, payload)
		{
			state.currentData = payload;

			state.title = "Soumettre une candidature";

			Vue.set(state, "isReady", true);

		},


		emptyForm: (state) =>
		{
			for( let strKey in state.userData)
			{
				state.userData[strKey]  = "";
			}
		},

		initFromStorage (state)
		{
			let strStorage = localStorage.getItem("form");


			let storage = JSON.parse(strStorage);


			Vue.set(state, "userData", storage);

		},

		cleanStorage (state)
		{
			localStorage.removeItem("form");
			localStorage.removeItem("formId");
			localStorage.clear();
		},


		formId: (state, payload) =>
		{
			state.form_id = payload;

			let formId = localStorage.getItem("formId");

			if (formId !== payload)
			{
				localStorage.clear();
				localStorage.setItem("formId", payload);
			}

		},

		submitId: (state, payload) =>
		{
			state.submit_id = payload;
		},


		userData: (state, payload) =>
		{
			if (!state.userData)
			{
				Vue.set(state, "userData", {});
			}

			Vue.set(state.userData, payload.key.toString(), payload.value);

			localStorage.setItem("form", JSON.stringify( state.userData));

		},


		next_group(state)
		{
			state.currentGroup++;
		},

		prev_group(state)
		{
			if (state.currentGroup > 0)
				state.currentGroup--;
		}
	};


let storeSPA =
	{
		state,
		getters,
		mutations,
		actions
	}
;

Vue.use(Vuex);
const store = new Vuex.Store(storeSPA);

export default store;
