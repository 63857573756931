<template>
    <component :is="component" :field="field" :type="type" v-if="component"></component>
</template>

<script>
    export default {
        name: "input-loader",
        props: ["field", "type"],

        data ()
        {
            return {
                component : null,
                vueFile: ""
            }
        },

        computed:
            {
                id ()
                {
                    let input = this.$children[0];

                    return input.id;
                },

                loader ()
                {

                    if (!this.type)
                        return null;

                    //console.log("this.vueFile", this.vueFile);
                  return () => import('./' + this.getInputFromType(this.type))

                },


                validators ()
                {
                    return this.field.validators.split("|");
                },

                errors ()
                {
                    //let input = this.$children[0];
                    let errors =  this.field.errors;

                    let arrSplit = errors.split("|");

                    if (arrSplit.length === 1 && arrSplit[0].length === 0)
                        return [];

                    return arrSplit.map( e => { return {validator: e.split(":")[0], message: e.split(":")[1]}});
                }
            },


        methods:
            {
                getInputFromType (type)
                {

                    if (type === "select_multiple")
                    {
                        return "InputSelect.vue";
                    }
                    else if (type === "upload")
                    {
                        return "InputUploader.vue";
                    }
                    else if (type === "textarea")
                    {
                        return "InputTextArea.vue";
                    }
                    else if (type === "radio")
                    {
                        return "InputRadio.vue";
                    }
                    else if (type === "date")
                    {
                        return "InputDate.vue";
                    }
                    else if (type === "info")
                    {
                        return "InputInfo.vue";
                    }
                    else if (type === "checkbox")
                    {
                        return "InputCheckbox.vue";
                    }
                    else if (type === "hidden")
                    {
                        return "InputHidden.vue";
                    }

                    return "InputText.vue";
                },


                validate ()
                {
                    let input = this.$children[0];
                    input.tempMode = "";

                    let data = input.value;
                    let arrValidators = this.validators;


                    for (let i = 0; i < arrValidators.length; i++)
                    {
                        let strValidator = arrValidators[i];

                        if (!this.validateValue (data, strValidator, arrValidators, input))
                        {

                            let strMessage = this.getErrorMessage(strValidator, input);
                            input.setErrorMode(strMessage);

                            return false;
                        }
                    }

                    return true;
                },

                validateValue (value, validator, arrValidators, input)
                {


                    if (validator === "required")
                    {

                         return (value !== undefined && value !== null) && ((value.length > 0) || (value > 0));

                    }
                    else if (validator === "email")
                    {
                        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

                        return reg.test(value);

                    }

                    return true;
                },

                getErrorMessage (validator, input)
                {
                    let arrErrors = this.errors;

                    let objError = arrErrors.find( e => e.validator === validator);

                    if (input.field.type === "upload")
                    {
                        return "Erreur upload : " + input.mode;
                    }

                    if (!objError)
                    {
                        if (validator === "required")
                            return "Ce champ est requis";

                        return "Erreur";
                    }



                    return objError.message;
                }
            },


        mounted() {


            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('./InputText.vue')
                })
        },


    }
</script>

<style scoped>

</style>