var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("section", { staticClass: "card" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "form",
            {
              staticClass: "form-horizontal form-bordered",
              attrs: { action: "#" }
            },
            [
              _c("div", { staticClass: "form-group row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "input-group" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.messageSuccess) }
                    })
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "card-header" }, [
      _c("h2", { staticClass: "card-title" }, [_vm._v("Confirmation")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }