var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.component
    ? _c(_vm.component, {
        tag: "component",
        attrs: { field: _vm.field, type: _vm.type }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }