<template>

    <div class="inner-wrapper">

        <img style="" src="/_/images/logo.jpg" height="200px"/>

        <section role="main" class="content-body card-margin" v-if="isSubmitted">
            <h1> {{ title }}</h1>
            <input-success v-if="isInit"></input-success>
        </section>



        <section role="main" class="content-body card-margin" v-if="isFormSelection">

            <h1>{{ currentData.title }}</h1>


            <div class="form-group row">

                <div class="col">
                    <div class="input-group">
                        <span v-html="currentData.splash"></span>
                    </div>

                </div>
            </div>

                <button
                    type="button"
                    class="mb-1 mt-1 mr-1 btn btn-default"
                    @click="selectForm('form1')"
                >{{ currentData.button1 }}</button>


            <button
                type="button"
                class="mb-1 mt-1 mr-1 btn btn-default"
                @click="selectForm('form2')"
            >{{ currentData.button2 }}</button>

        </section>

        <section role="main" class="content-body card-margin" v-if="isInit && !isSubmitted">
            <h1> {{ title }}</h1>



            <group-section
                    v-for="(group, index) in groups"
                    :title=group.key
                    :key="getKey('group', index)"
                    :fields=group.fields

                    v-if="isActiveGroup(index)"
                    :ref="getKey('group', index)"
                    >

            </group-section>



            <button
                    type="button"
                    class="mb-1 mt-1 mr-1 btn btn-default"
                    @click="prevGroup"
                    v-if="!isFirst">Précédent ({{ prevLabel }})</button>

            <button
                    type="button"
                    class="mb-1 mt-1 mr-1 btn btn-default"
                    @click="importForm"
                    v-if="isInit && isFirst && gotForm">Poursuivre l'inscription</button>

            <button
                    type="button"
                    class="mb-1 mt-1 mr-1 btn btn-default"
                    @click="resetForm"
                    v-if="isInit && isFirst && gotForm">Recommencer l'inscription</button>


            <button
                    type="button"
                    class="mb-1 mt-1 mr-1 btn btn-default"
                    @click="validate"
                    v-if="isInit && isFirst && !gotForm">Débuter</button>


            <button
                    type="button"
                    class="mb-1 mt-1 mr-1 btn btn-default"
                    @click="validate"
                    v-if="!isFirst && !isLast && isInit">Continuer</button>


            <button
                    type="button"
                    class="mb-1 mt-1 mr-1 btn btn-default"
                    @click="validate"
                    v-if="isLast">Soumettre votre candidature</button>
        </section>
    </div>


</template>

<script>

    import Vue      from "vue";
    import {mapState} from "vuex";
    import _        from 'lodash';
    import VueScrollTo from "vue-scrollto";
    import GroupSection from "./forms/GroupSection.vue";
    import InputInfo from "./forms/InputInfo.vue";
    import InputSuccess from "./forms/InputSuccess.vue";

    Object.defineProperty(Vue.prototype, '$_', { value: _ });

    export default {
        name: "form-wizard",
         components:  {InputSuccess, InputInfo, GroupSection },

        data() {
            return {
                mode: "",
                bInit: false,
                backdoor: 1
            }
        },
        computed:
            {
                ...mapState(["currentData", "formId"]),
                currentGroup ()
                {
                    return this.$store.state.currentGroup;
                },

                isFirst ()
                {
                    return this.$store.state.currentGroup === 0;
                },

                isLast ()
                {
                    return   this.$store.state.currentGroup === (this.groups.length - 1);
                },

                isInit()
                {

                    return this.$store.state.fields.length > 0;
                },

                isReady ()
                {
                    return this.$store.state.isReady;
                },

                isFormSelection ()
                {
                    return this.isReady && !this.isInit;
                },

                isSubmitted ()
                {
                    return this.mode === "submitted";
                },


                title ()
                {
                    return this.$store.state.title;
                },




                groups ()
                {
                    let fields = this.$store.state.fields;

                    let arrGroups = fields.map( f => f.group );
                    let setGroups = new Set(arrGroups);
                    arrGroups = [...setGroups];

                    let objGroups = [];

                    for (let i  = 0; i < arrGroups.length; i++)
                    {
                        let strKey = arrGroups[i];
                        let group = {key: strKey};

                        group.fields = fields.filter( f => f.group === strKey);

                        objGroups.push(group);
                    }



                    return objGroups;
                },

                prevLabel ()
                {
                    if (this.$store.state.currentGroup === 0)
                        return "";

                    let iPrevGroup = this.$store.state.currentGroup - 1;
                    let strPrevLabel = this.groups[iPrevGroup].key;

                    return strPrevLabel;
                },


                formSubmitField ()
                {
                    return { label: "Hello world"};
                },

                gotForm ()
                {
                    this.backdoor;

                    let form = localStorage.getItem("form");

                    return  form;
                },


            },


        methods:
            {


                isActiveGroup (index)
                {
                    return this.$store.state.currentGroup === index;
                },

                prevGroup ()
                {
                    this.$store.commit("prev_group");
                },

                selectForm (strKey)
                {
                    let form = this.currentData[strKey];
                    this.$store.commit("form", form);
                    this.$store.commit("formId", form.label_id);
                    this.$store.commit("initFromStorage");
                },


                importForm ()
                {

                    this.validate();
                },

                resetForm ()
                {
                    this.backdoor++;
                    this.$store.commit("cleanStorage");
                    this.$store.commit("emptyForm");
                    //this.validate();
                },


                validate ()
                {
                    let strKey = this.getKey("group", this.currentGroup);
                    let currentGroup = this.$refs[strKey][0];

                    let bOk = currentGroup.validate();



                    if (bOk)
                    {
                        if (!this.isLast)
                        {
                            this.nextGroup();
                        }
                        else
                        {
                            this.submit();
                        }
                    }
                    else
                    {
                        let firstError = currentGroup.getFirstError();


                        let errors = currentGroup.getErrors();


                        VueScrollTo.scrollTo("#" + firstError.id, 1);
                    }
                },





                nextGroup ()
                {
                    this.$store.commit("next_group");
                    VueScrollTo.scrollTo("body", 1);

                },

                getKey (prefix, key)
                {
                    return prefix + "-" + key;
                },

                progress ()
                {
                    this.$store.dispatch("progress", this.$store.state);
                },

                submit ()
                {
                    this.$store.dispatch("submit", this.$store.state);
                    this.$store.commit("cleanStorage");

                    this.mode = "submitted";
                }
            },


        async mounted ()
        {
            //this.$store.dispatch("get_form", "soumettre-une-candidature-saison-2021-2022");
            await this.$store.dispatch("init"); //strCurrentForm is injected globally

            //await this.$store.dispatch("userData", {key:"218", value: navigator.userAgent});//THIS IS A HACK THAT WILL ONLY WORK IN FORM 18
        }
    }
</script>

<style scoped>

    button
    {
        color:#FFFFFF;
        background: rgb(245,136,165);
        background: linear-gradient(90deg, rgba(245,136,165,1) 0%, rgba(245,144,160,1) 35%, rgba(249,170,137,1) 100%);

    }
</style>