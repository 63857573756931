<template>
    <div class="row">
        <div class="col">
            <section class="card">
                <header class="card-header">
                    <h2 class="card-title">{{ title }}</h2>
                </header>
                <div class="card-body">
                    <form class="form-horizontal form-bordered" action="#">

                        <input-loader
                                :field="field"
                                :type="field.type"
                                :key="getKey('field', field.id)"
                                :ref="getKey('field', field.id)"
                                v-if="canBeShown(field)"
                                v-for="field in fields"></input-loader>

                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import InputLoader from "./InputLoader.vue";
    export default {
        name: "GroupSection",
        components: {InputLoader},
        props: ["title", "fields"],


        methods:
            {
                getKey (strPrefix, key)
                {
                    return strPrefix + "-" + key;
                },

                validate ()
                {
                    let valid = true;

                    for (let i = 0; i < this.$children.length; i++)
                    {
                        let comp = this.$children[i];

                        if (!comp.validate())
                        {
                            valid = false;
                        }
                    }

                    return valid;
                },

                getFirstError ()
                {
                    for (let i = 0; i < this.$children.length; i++)
                    {
                        let comp = this.$children[i];

                        if (!comp.validate())
                        {
                            return comp;
                        }
                    }

                    return null;
                },


                getErrors ()
                {
                    let errors = [];

                    for (let i = 0; i < this.$children.length; i++)
                    {
                        let comp = this.$children[i];

                        if (!comp.validate())
                        {
                            errors.push(comp);
                        }
                    }

                    return errors;
                },


                canBeShown (field)
                {
                    if (field.suffix !== "")
                    {

                        let arrChoices = field.suffix.split("|");

                        for (let i = 0; i < arrChoices.length; i++)
                        {
                          let strField = arrChoices[i];

                          let strID = strField.split(":")[0];
                          let strValue = strField.split(":")[1];

                          let strCurrent = this.$store.state.userData[strID];

                          let bDependencyFulfilled = strCurrent === strValue;

                          if (bDependencyFulfilled)
                            return true;
                        }

                        return false;
                    }

                    return true;
                }
            }
    }
</script>

<style scoped>

</style>