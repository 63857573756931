import SiOnSAimait from "./SiOnSAimait.js";

function onReady ()
{

    window.main = new SiOnSAimait();

}



document.addEventListener('DOMContentLoaded', onReady);

