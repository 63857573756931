<template>
    <div class="form-group row">

        <div class="col">
            <div class="input-group">
                <span v-html="field.label"></span>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "InputInfo",
        props: ["field"],
        data ()
        {
          return {
              mode: "",
              error: ""
          }
        },


        computed:
            {
                value:
                    {
                        get ()
                        {
                            return "";
                        },

                        set (value)
                        {

                        }
                    }
            },


    }
</script>

<style scoped>

</style>